<template>
  <div>
    <v-row>
      <v-col cols="12"
             md="3"
             sm="6"
             order="1"
             class="align-self-end">
        <h1>Welcome back</h1>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    components: {
}}
</script>
